const transformItemsVendor = (itemsVendor) => {
    if (!Array.isArray(itemsVendor)) {
        return [];
    }
    if (itemsVendor.length > 0) {
        return itemsVendor.reduce((acc, vendor) => {
            const items = vendor.items.map((item) => ({
                ...item,
                id: item.id,
                product: item.product,
                prices: item.prices,
                quantity: item.quantity,
                preorder: item.preorder,
                preorder_time: item.preorder_time,
                vendor_stock_information: item.vendor_stock_information,
            }));
            return acc.concat(items);
        }, []);
    }
    return [];
};

export default transformItemsVendor;
